import type { ComponentProps } from 'react';
import { Icon, type IconProps } from '#/common/components/Icon';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '#/common/components/ui/dialog';

interface InfoShippingProps extends ComponentProps<'div'> {
  icon: IconProps['name'];
  legend: string[];
  title: string;
  description?: string;
}

export function InfoShipping({
  icon,
  title,
  legend,
  description,
  children,
}: InfoShippingProps) {
  return (
    <div className="flex flex-col items-center justify-between rounded-md border border-gray-300 p-4">
      <div className="flex flex-col items-center">
        <div className="mb-1.5 flex">
          <Icon
            name={icon}
            size={24}
          />
        </div>
        <div className="flex flex-col">
          {legend.map((item) => (
            <p key={item}>{item}</p>
          ))}
        </div>
      </div>
      <Dialog>
        <DialogTrigger className="mt-2 font-medium text-black uppercase underline hover:text-brand-main dark:text-white dark:hover:text-brand-dark">
          Saiba mais
        </DialogTrigger>
        <DialogContent className="max-w-[320px] rounded-md sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <DialogDescription className="text-black/80 dark:text-white/80">
            {description}
            <div className="flex flex-col gap-3">{children}</div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </div>
  );
}
