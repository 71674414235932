import Image from 'next/image';
import type { ComponentProps } from 'react';
import type { Images } from '#/common/types/images';
import { cn } from '#/utils/cn';

interface CataloguesProps extends ComponentProps<'div'> {
  images: Images;
}

export function Catalogues({ images, className }: CataloguesProps) {
  return (
    <div className={cn(className, 'grid gap-3 sm:grid-cols-2 md:gap-4')}>
      {images.map(({ alt, href, src }, index) => (
        <a
          key={`${index}-${src}`}
          href={href}
          className="overflow-hidden rounded-md"
        >
          <Image
            alt={alt}
            src={src}
            className="w-full object-cover transition-all duration-300 hover:scale-110"
          />
        </a>
      ))}
    </div>
  );
}
