import type { ComponentProps } from 'react';
import { cn } from '#/utils/cn';

export function CardsSectionRoot({ className, children }: ComponentProps<'section'>) {
  return (
    <section className={cn(className, 'my-11 flex flex-col gap-4 sm:gap-6 lg:my-14')}>
      {children}
    </section>
  );
}
