import type { ImageProps } from 'next/image';
import Link from 'next/link';
import type { ComponentProps } from 'react';
import { ImageWithFallback } from '#/common/components/ImageWithFallback';
import type { GenericProduct } from '#/common/types/generic-product';

export interface GalleryCardsProps extends ComponentProps<'div'> {
  editionImage: ImageProps;
  editionLink: string;
  images: GenericProduct[];
}

export function GalleryCards({ editionLink, editionImage, images }: GalleryCardsProps) {
  const galleryProducts = images.slice(0, 4);
  const threeImages = galleryProducts.slice(0, 3);

  return (
    <div className="mx-auto flex aspect-square max-h-[660px] w-full max-w-[800px] flex-col gap-3 rounded-md border border-black-5 p-3 sm:flex-row sm:gap-4 sm:p-4">
      <div className="flex overflow-hidden rounded-md">
        <Link
          href={editionLink}
          className="relative"
          prefetch={false}
        >
          <ImageWithFallback
            src={editionImage.src}
            className="aspect-square object-cover object-center xs:object-bottom transition-all duration-300 hover:scale-110 sm:h-full sm:object-center"
            alt={editionImage.alt || 'Imagem da edição do mês'}
            width={600}
            height={600}
          />
        </Link>
      </div>
      <div className="flex justify-between gap-3 sm:max-w-[200px] sm:flex-col">
        {threeImages.map(({ slug, product_id, image, title }) => (
          <Link
            key={product_id}
            href={`/catalogo/produto/${slug}`}
            prefetch={false}
            className="aspect-square overflow-hidden rounded-md"
          >
            <ImageWithFallback
              className="duration-300 hover:scale-110"
              alt={image.alt}
              src={image.src}
              productName={title}
              width={400}
              height={400}
            />
          </Link>
        ))}
      </div>
    </div>
  );
}
