import { HomePage } from '#/features/home/view/Template';
import { trpcHelper } from '#/server/helpers';

export async function getStaticProps() {
  try {
    const helpers = trpcHelper();

    await Promise.allSettled([
      // helpers.couponRouter.highlightedCoupon.prefetch(),
      helpers.pageRouter.header.prefetch(),
      helpers.pageRouter.footer.prefetch(),
      helpers.pageRouter.getHomePage.prefetch(),
    ]);

    return {
      props: {
        trpcState: helpers.dehydrate(),
      },
      revalidate: 30, // Revalida a página a cada 30 segundos
    };
  } catch (error) {
    console.error(error);
  }
}

export default HomePage;
