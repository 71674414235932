import { Button } from '#/ui/button';
import { scrollToTop } from '#/utils/scrollToTop';

export function ScrollToTopButton() {
  return (
    <Button
      className="mx-auto flex justify-center"
      color="link"
      onClick={scrollToTop}
    >
      Voltar ao Topo
    </Button>
  );
}
