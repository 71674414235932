import type { ComponentProps } from 'react';
import { Button } from '#/common/components/ui/button';

type CardsSectionFooterProps = ComponentProps<'a'>;

export function CardsSectionFooter({
  children,
  className,
  ...props
}: CardsSectionFooterProps) {
  return (
    <footer className="mx-auto mt-4 flex">
      <Button
        asChild
        className={className}
      >
        <a
          className="flex items-center gap-2"
          {...props}
        >
          {children}
        </a>
      </Button>
    </footer>
  );
}
