import type { ComponentProps } from 'react';

interface CardsSectionHeaderProps extends ComponentProps<'header'> {
  title: string;
  center?: boolean;
}

export function CardsSectionHeader({
  title,
  center = false,
  children,
}: CardsSectionHeaderProps) {
  return (
    <header
      data-center={center}
      className="text-left data-[center=true]:text-center"
    >
      <h2 className="font-semibold text-xl uppercase">{title}</h2>
      {children}
    </header>
  );
}
